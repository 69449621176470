<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-form :inline="true" class="demo-form-inline">
      <el-form-item label="手机号">
        <el-input v-model="phone" clearable></el-input>
      </el-form-item>
      <el-form-item label="商户名">
        <el-input v-model="mechanism_name" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-table
        :data="payList"
        :fit="true"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{ 'text-align': 'center' }"
        v-loading="is_loading"
      >
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="商户名" prop="mechanism_name"></el-table-column>
        <el-table-column
          label="可核销数量"
          prop="restrictions_count"
        ></el-table-column>

        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button type="primary" @click="update(row)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <paging-fy
        @currentPageChange="changeCurrentPage"
        :currentPage="currentPage"
        :total="payTotal"
      ></paging-fy>
    </el-row>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :width="kd ? '95%' : '30%'"
    >
      <el-input v-model="count" placeholder="请输入限制数量"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitupdate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      kd: "",
      dialogVisible: false,
      nav: { firstNav: "优惠券管理中心", secondNav: "优惠券发放记录" },
      mechanism_id: "",
      mechanism_name: "",
      mechanism_list: [],
      payList: [],
      status: null,
      is_loading: true,
      refuseReason: false,
      showReason: false,
      refuse_contect: "",
      is_loading: false,
      currentPage: 1,
      payTotal: 0,
      temp: {},
      status: "true",
      phone: "",
      mechanism_name_list: [],
      amount: "",
      nickname: "",
      count: "10",
      cur: {},
    };
  },
  mounted() {
    this.getRefoundList();
    this.kd = window.screen.width < 768;
  },
  methods: {
    // 机构查询远程查询
    searchOrgName(query) {
      let url = "/user/mastermechanism/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            type: this.channels,
            mechanism_name:
              typeof query == "string" && query != "" ? query : null,
            pageSize: 10,
            currentPage: 1,
            status: 2,
          },
        })
        .then((res) => {
          // console.log(res);
          this.mechanism_name_list = res.data.data.rows;
        })
        .catch((err) => {});
    },
    //
    getRefoundList() {
      this.is_loading = true;
      let url = "/user/mastermechanism/queryByMessage";
      let params = {
        pageSize: 10,
        currentPage: this.currentPage,
        type: "商户",
      };
      if (this.phone) {
        params.mechanism_telephone = this.phone;
      }
      if (this.mechanism_name) {
        params.mechanism_name = this.mechanism_name;
      }

      this.$axios
        .get(url, {
          params,
        })
        .then((res) => {
          this.payList = res.data.data.rows;
          this.payTotal = res.data.data.total;
          this.is_loading = false;
        });
    },
    search() {
      this.currentPage = 1;
      this.getRefoundList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getRefoundList();
    },
    refuse(row) {
      this.temp = row;
      this.refuseReason = true;
    },
    showRefuse(row) {
      console.log(row);
      this.temp = row;
      this.showReason = true;
    },
    update(row) {
      this.dialogVisible = true;
      this.cur = row;
      this.count = row.restrictions_count;
    },
    submitupdate() {
      let data = {
        id: this.cur.id,
        restrictions_count: this.count,
      };
      this.$axios({
        method: "post",
        url: "/user/mastermechanism/update",
        data: data,
      })
        .then((res) => {
          if (res.data.code == 0) {
            //成功
            this.$message({
              message: res.data.message,
              type: "success",
            });
          }
          this.dialogVisible = false;
          this.getRefoundList();
        })
        .catch((error) => {});
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.refuseReason = false;
          this.showReason = false;
          this.temp = {};
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="less">
.expand-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5;
  .el-form-item {
    width: 40%;
  }
}
</style>
